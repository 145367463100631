* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-family: "Montserrat", sans-serif;
}

body {
    background: #eee;
    padding: 0 16px;
    background-color: #338c00;
    background-color: #8c8c8c;
    background-image: url("https://www.transparenttextures.com/patterns/black-thread-light.png");
}

header {
    background-color: #f5ba13;
    margin: auto -16px;
    padding: 16px 32px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

header h1 {
    color: #fff;
    font-family: "McLaren", cursive;
    font-weight: 200;
}

footer {
    position: fixed;
    text-align: center;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
}

footer p {
    color: #ccc;
}

#edit-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

div.edit-box {
    margin: 10px 0;
}

.edit-box label {
    font-weight: bold;
    font-size: 20px;
}

.edit-box label::after {
    content: "\A";
    white-space: pre;
}

.edit-box input {
    padding: 5px;
    width: 70vw;
    font-size: 18px;
    border-radius: 10px;
}

textarea.content {
    padding: 5px;
    height: 300px;
    width: 70vw;
    font-size: 18px;
    border-radius: 10px;
}

#button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin: 0 15vw;
}